import Airtable from "airtable";

export function getAllDecks() {
  return new Promise((resolve, reject) => {
    const API_KEY = process.env.VUE_APP_AIRTABLE_TOKEN;
    console.log("the API_KEY is:", API_KEY);
    Airtable.configure({
      endpointUrl: "https://api.airtable.com",
      apiKey: API_KEY,
    });
    var base = Airtable.base("appn0WDLC3h9ohCW3");
    var availableDecks = [];
    base("Decks")
      .select({
        // maxRecords: 100,
        fields: [
          "Name",
          "Created By",
          "Deck Blurb",
          "Deck Icon",
          "rawIconSVG",
          "Icon Color",
          "Deck Box Color",
          "Published",
        ],
        view: "Grid view",
      })
      .eachPage(
        function page(records, fetchNextPage) {
          // This function (`page`) will get called for each page of records.
          records.forEach(function (record) {
            if (record.get("Published") == true) {
              var thisDeck = {};
              thisDeck.name = record.get("Name");
              thisDeck.creator = record.get("Created By");
              thisDeck.blurb = record.get("Deck Blurb");
              //Get fallback SVG icon url
              var svgData = record.get("Deck Icon");
              if (svgData !== undefined) {
                thisDeck.svgURL = svgData[0].url;
              } else {
                thisDeck.svgURL = undefined;
              }
              //Get raw SVG paths data
              thisDeck.rawSvg = record.get("rawIconSVG");
              //Get raw SVG color paths
              thisDeck.iconColor = record.get("Icon Color");
              thisDeck.boxColor = record.get("Deck Box Color");
              //setup props for DeckPicker page
              thisDeck.isSelected = false;
              availableDecks.push(thisDeck);
              // console.log("thisDeck: ", thisDeck);
            }
          });
          // To fetch the next page of records, call `fetchNextPage`.
          // If there are more records, `page` will get called again.
          // If there are no more records, `done` will get called.
          fetchNextPage();
        },
        function done(err) {
          if (err) {
            reject(err);
          } else {
            resolve(availableDecks);
          }
        }
      );
  });
}
