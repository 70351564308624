<template>
  <div class="navigation" :class="isWhatIf && `what-if`">
    <router-link to="/">
      <div class="logo-div">
        <img class="nav-logo" src="../assets/Feedback-Loop.png" />
        <h1>
          <!--  Digital Card Decks -->
          <span class="deck-name" v-if="gameInSession && !isWhatIf">
            {{ gameInSession }}
          </span>
          <span class="deck-name" v-if="isWhatIf">What If?</span>
        </h1>
      </div>
    </router-link>

    <div class="right-side-links">
      <!-- <div id="links-open-in-tab" v-if="gameInSession">
        <router-link :to="{ name: 'ExtendedResources' }" target="_blank"
          >Extended Resources
        </router-link>
        <router-link to="/About" target="_blank">About</router-link>
      </div>
      <div id="links-open-in-self" v-if="!gameInSession">
        <router-link :to="{ name: 'ExtendedResources' }"
          >Extended Resources
        </router-link>
        <router-link to="/About">About</router-link>
      </div> -->
      <a href="https://www.moreandmore.world/" target="_blank">
        About More&More
      </a>
    </div>
  </div>
</template>
<script>
export default {
  name: "Navigation",
  data() {
    return {};
  },
  computed: {
    gameInSession() {
      return this.$route.params.deckId;
    },
    isWhatIf() {
      return this.$route.params.deckId === `what if`;
    },
  },
  methods: {},
};
</script>
<style scoped>
.navigation {
  position: fixed;
  z-index: 2000;
  top: 0;
  width: 100vw;
  height: var(--var-nav-height);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 0;
  background-color: black;
  color: white;
  box-sizing: border-box;
  text-transform: uppercase;
  padding-top: 0.2rem;
}

/* STYLE OVERRIDES FOR WHAT IF? DECK */
.navigation.what-if {
  /* background-color: var(--var-whatIf-teal); */
  color: white;
}
.nav-logo {
  height: 5rem;
  position: fixed;
  left: 1rem;
  top: 0.4rem;
  margin: 0;
}

.navigation h1 {
  margin: 0 0 0 6rem;
  padding: 0;
  display: inline-block;
  font-size: 1.45rem;
  line-height: 1;
  color: var(--var-IIF-yellow);
}
.navigation h1,
.navigation h1 a {
  text-decoration: none;
  text-transform: uppercase;
}
.navigation h1 .deck-name {
  display: block;
  font-family: "Univers-black", Arial, Helvetica, sans-serif;
  font-size: 1.6rem;
  line-height: 1.1;
  color: var(--var-IIF-dark-teal);
  text-shadow: 2px 2px rgba(255, 255, 255, 0.5);
}

/* STYLE OVERRIDES FOR WHAT IF? DECK */
.navigation.what-if h1,
.navigation.what-if h1 a,
.navigation h1 .deck-name {
  color: white;
  text-shadow: none;
}
.navigation .created-by {
  display: inline-block;
  font-family: Arial, Helvetica, sans-serif;
  color: var(--var-IIF-dark-teal);
  text-transform: none;
  margin-left: 1rem;
  font-size: 1.4rem;
}
.navigation .guide-book-btn {
  cursor: pointer;
}
.right-side-links {
  font-family: var(--var-IIF-sans-body);
  font-size: 1.1rem;
  margin-right: 0.2rem;
}
.right-side-links a {
  color: white;
  font-weight: bold;
  padding: 0.5rem 1rem;
  text-decoration: none;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.3rem;
  margin-right: 1rem;
}
.right-side-links a:last-child {
  margin: 0;
}
.right-side-links a:hover {
  /* transition: border-color 0.3s; */
  transition: color 0.3s;
  transition: background-color 0.3s;
  color: var(--var-IIF-cyan);
  border-color: var(--var-IIF-cyan);
  background-color: var(--var-IIF-darker-teal);
}

/* STYLE OVERRIDES FOR WHAT IF? DECK */
.navigation.what-if .right-side-links,
.navigation.what-if .right-side-links a {
  color: white;
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {
  .feedback-loop-logo {
    top: 0.2rem;
  }
  .navigation h1 {
    font-size: 1.8rem;
    line-height: 1;
  }

  .right-side-links {
    font-size: 1.4rem;
    margin-right: 2rem;
  }
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
  .feedback-loop-logo {
    height: 5rem;
    position: fixed;
    left: 1rem;
    top: -0.4rem;
    margin: 0;
  }
  .navigation h1 {
    margin: 0 0 0 7rem;
    padding: 0;
    display: inline-block;
    font-size: 2.8rem;
    color: white;
  }
  .navigation h1,
  .navigation h1 a {
    text-decoration: none;
    text-transform: uppercase;
  }
  .navigation h1 .deck-name {
    display: inline;
    font-size: 2.7rem;
  }
  .navigation .created-by {
    display: inline-block;
    margin-left: 1rem;
    font-size: 1.4rem;
  }
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
}
</style>
