import { createRouter, createWebHistory } from "vue-router";
import DeckPicker from "../views/DeckPicker.vue";

const DEFAULT_TITLE = "More & More";

//  Use the "title" property to set the name of pages :)
const routes = [
  {
    path: "/",
    name: "Deck Picker",
    component: DeckPicker,
    meta: {
      title: DEFAULT_TITLE,
    },
  },
  // {
  //   path: "/deck-viewer",
  //   name: "DeckViewer",
  //   component: () =>
  //     import(/* webpackChunkName: "deckpicker" */ "../views/DeckViewer.vue"),
  //   meta: {
  //     title: "Investing in Futures",
  //   },
  // },
  {
    path: "/play/:deckId",
    name: "GameMain",
    props: true,
    component: () =>
      import(/* webpackChunkName: "gamemain" */ "../views/GameMain.vue"),
    meta: {
      title: `${DEFAULT_TITLE}`,
    },
  },
  {
    path: "/one-card/:deckId",
    name: "SingleCardPicker",
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "singlecardpicker" */ "../views/SingleCardPicker.vue"
      ),
    meta: {
      title: `${DEFAULT_TITLE}: Draw One Card`,
    },
  },
  {
    path: "/about",
    name: "About",
    props: true,
    component: () =>
      import(/* webpackChunkName: "About" */ "../views/About.vue"),
    meta: {
      title: `About ${DEFAULT_TITLE}`,
    },
  },
  {
    path: "/extended-resources",
    name: "ExtendedResources",
    props: true,
    component: () =>
      import(/* webpackChunkName: "About" */ "../views/ExtendedResources.vue"),
    meta: {
      title: `${DEFAULT_TITLE} | Extended Resources`,
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    // always scroll to top
    return { top: 0 };
  },
});

//Sets the title of the page
router.beforeEach((to, from, next) => {
  if (`${to.meta.title}` == null) {
    document.title = DEFAULT_TITLE;
    next();
  } else {
    document.title = `${to.meta.title}`;
    next();
  }
});

export default router;
