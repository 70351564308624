<template>
  <Navigation />
  <router-view />
</template>

<script>
// @ is an alias to /src
import Navigation from "@/components/TheNavigation.vue";
export default {
  components: {
    Navigation,
  },
};
</script>

<style>
@font-face {
  font-family: "univers_condensed";
  src: url("../public/unvr57x-webfont.woff2") format("woff2"),
    url("../public/unvr57x-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "univers_condensed";
  src: url("../public/unvr67x-webfont.woff2") format("woff2"),
    url("../public/unvr67x-webfont.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Univers-xblack";
  src: url("../public/UniversLTStd-XBlack.woff2") format("woff2"),
    url("../public/UniversLTStd-XBlack.woff") format("woff");
  font-weight: 850;
  font-style: normal;
  font-display: swap;
}

/* @font-face {
  font-family: "Univers-bold";
  src: url("../public/UniversLTStd-Bold.woff2") format("woff2"),
    url("../public/UniversLTStd-Bold.woff") format("woff");
  font-weight: 650;
  font-style: normal;
  font-display: swap;
} */

@font-face {
  font-family: "Univers-black";
  src: url("../public/UniversLTStd-Black.woff2") format("woff2"),
    url("../public/UniversLTStd-Black.woff") format("woff");
  font-weight: 750;
  font-style: normal;
  font-display: swap;
}

:root {
  --var-IIF-yellow: #efff33;
  --var-IIF-teal: #63c7c9;
  --var-IIF-cyan: #c1fafb;
  --var-IIF-dark-teal: #309b9d;
  --var-IIF-darker-teal: #145a88;
  --var-IIF-blue: #0012b4;
  --var-IFF-vibrant-blue: #1135eb;
  --var-IIF-red: #ff0000;
  --var-IIF-darker-red: #e03f3f;
  --var-IIF-sans-body: Helvetica, Arial, sans-serif;
  --var-IIF-sans: "univers_condensed", Avenir, Helvetica, Arial, sans-serif;
  --var-IIF-sans-bold: "Univers-bold", Arial, Helvetica, sans-serif;
  --var-IIF-sans-black: "Univers-black", Arial, Helvetica, sans-serif;
  --var-IIF-sans-heavy: "Univers-black", Arial, Helvetica, sans-serif;
  --var-whatIf-teal: #006082;
  --var-whatIf-yellow: #b5ce00;
  --var-nav-height: 6rem;
}
#app {
  font-family: "univers_condensed", Avenir, Helvetica, Arial, sans-serif;
  font-size: 1.4rem;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
html {
  font-size: 10px;
}
body {
  margin: 0;
  background-color: var(--var-IIF-cyan);
}
.container {
  padding-top: var(--var-nav-height);
}

/* MARKDOWN STYLING */
.markdown > * {
  box-sizing: border-box;
}
.markdown h1 {
  font-family: var(--var-IIF-sans-heavy);
  font-size: 3.2rem;
  text-transform: uppercase;
  color: black;
}
.markdown h2 {
  font-family: var(--var-IIF-sans-heavy);
  font-size: 2.2rem;
  text-transform: uppercase;
  color: var(--var-IIF-dark-teal);
  margin: 6rem 0 1rem 0;
}
.markdown h3 {
  font-size: 2.8rem;
  color: var(--var-IIF-blue);
  margin: 0 0 0.4rem 0;
}
.markdown p {
  font-family: var(--var-IIF-sans-body);
  font-size: 1.6rem;
  line-height: 2.2rem;
  color: black;
  margin: 0 0 2.2rem 0;
}
.markdown blockquote {
  border-left: 4px solid var(--var-IIF-dark-teal);
  padding-left: 2rem;
}
.markdown ul li {
  color: black;
  font-family: var(--var-IIF-sans-body);
  font-size: 1.6rem;
  line-height: 2.2rem;
  margin-bottom: 1rem;
}
.markdown ul {
  margin-bottom: 3rem;
}

/* GUIDEBOOK MARKDOWN STYLING */
.markdown-guidebook {
  padding: 4rem 2rem 14rem 2rem;
}
.markdown-guidebook > * {
  font-family: var(--var-IIF-sans-body);
  margin: 0;
}
.markdown-guidebook a,
.markdown-guidebook a:visited {
  color: var(--var-IIF-darker-teal);
}
.markdown-guidebook a:hover {
  color: var(--var-IIF-blue);
}

.markdown-guidebook > h1,
.markdown-guidebook > h2,
.markdown-guidebook > h3 {
  font-family: var(--var-IIF-sans-heavy);
  text-transform: none;
}
.markdown-guidebook h1 {
  font-size: 2.4rem;
  color: black;
  margin-bottom: 2.2rem;
  text-transform: uppercase;
}
.markdown-guidebook h2,
.markdown-guidebook h3 {
  color: black;
}
.markdown-guidebook h2 {
  margin-top: 4.5rem;
  margin-bottom: 0;
}
.markdown-guidebook h3 {
  font-family: var(--var-IIF-sans-body);
  font-weight: bolder;
  text-transform: uppercase;
  margin-top: 0.3rem;
  margin-bottom: 0.8rem;
}
.markdown-guidebook h4 {
  font-family: var(--var-IIF-sans-body);
  font-weight: bolder;
  font-size: 1.8rem;
  color: var(--var-IIF-blue);
  margin-top: 1.6rem;
  margin-bottom: 1.3rem;
}
.markdown-guidebook h5,
.markdown-guidebook h6 {
  color: #555;
  font-size: 1.6rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.markdown-guidebook h6 {
  font-weight: normal;
}
.markdown-guidebook p,
.markdown-guidebook ul li {
  font-family: var(--var-IIF-sans-body);
  font-size: 1.6rem;
  line-height: 2.2rem;
  margin-top: 1rem;
  margin-bottom: 2rem;
}
.markdown-guidebook ul {
  margin-top: 1rem;
  margin-bottom: 3rem;
}
.markdown-guidebook ul li {
  margin-top: 1.2rem;
  margin-bottom: 1.2rem;
}
</style>
